import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import {HomeComponent} from './home/home.component';
import {RsvpComponent} from './rsvp/rsvp.component';
import {VenueComponent} from './venue/venue.component';
import {AboutComponent} from './about/about.component';
import { RegisterComponent } from './register/register.component';

const routerOptions: ExtraOptions = {
  anchorScrolling: 'enabled',
};

const routes: Routes = [
  { path: '',  redirectTo: '/home', pathMatch: 'full' },
  { path: 'savethedate', component: HomeComponent },
  { path: 'home', component: HomeComponent },
  { path: 'rsvp', component: RsvpComponent },
  { path: 'venue', component: VenueComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'about', component: AboutComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
