<div class="navbar navbar-expand navbar-light bg-light footer justify-content-between">
    <ul class="navbar-nav">
        <!-- <li class="nav-item">
            <a class="nav-link" href="https://instagram.com">
                <fa-icon class="fa-lg" [icon]="faInstagram"></fa-icon>
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link" href="https://www.facebook.com">
                <fa-icon class="fa-lg" [icon]="faFacebook"></fa-icon>
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link" href="https://www.spotify.com">
                <fa-icon class="fa-lg" [icon]="faSpotify"></fa-icon>
            </a>
        </li> -->
    </ul>

    <ul class="navbar-nav" *ngIf="!mobile">
        <li class="nav-item">
            <a class="nav-link calligraphy" href="https://www.tomcheney.com">Site by the Groom</a>
        </li>

        <li class="nav-item">
            <a class="nav-link calligraphy" routerLink="/">Calligraphy by the Southsea Scribbler</a>
            <!--      <a class="nav-link" routerLink="/about">&copy; Tom &amp; Tor 2021</a>-->
        </li>
    </ul>

    <ul class="navbar-nav flex-column" *ngIf="mobile && !tiny">
        <li class="nav-item">
            <a class="nav-link calligraphy p-0 text-right" href="https://www.tomcheney.com">Site by the Groom</a>
        </li>

        <li class="nav-item">
            <a class="nav-link calligraphy p-0 text-right" href="https://instagram.com/southseascribbler">Calligraphy by the Southsea Scribbler</a>
            <!--      <a class="nav-link" routerLink="/about">&copy; Tom &amp; Tor 2021</a>-->
        </li>
    </ul>
</div>