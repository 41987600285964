import { Component, OnInit } from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {HttpClient, HttpParams} from '@angular/common/http';

interface MailChimpResponse {
  result: string;
  msg: string;
}

interface Country {
  name: string;
  value: string;
}

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  firstName: '';
  lastName: '';

  address1: string;
  address2: string;
  town: string;
  county: string;
  postcode: string;
  phone: string;
  country : Country;
  
  botString: '';

  waiting = false;
  submitted = false;
  success = false;
  mailChimpEndpoint = 'https://tomandtor.us1.list-manage.com/subscribe/post-json?u=4118053db977662ef278620b3&amp;id=e3136c0c02&';
  error = '';

  emailControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  countries: Country[] = [
    { value:"164", name: "USA" },
    { value:"286", name: "Aaland Islands" },
    { value:"274", name: "Afghanistan" },
    { value:"2", name: "Albania" },
    { value:"3", name: "Algeria" },
    { value:"178", name: "American Samoa" },
    { value:"4", name: "Andorra" },
    { value:"5", name: "Angola" },
    { value:"176", name: "Anguilla" },
    { value:"175", name: "Antigua And Barbuda" },
    { value:"6", name: "Argentina" },
    { value:"7", name: "Armenia" },
    { value:"179", name: "Aruba" },
    { value:"8", name: "Australia" },
    { value:"9", name: "Austria" },
    { value:"10", name: "Azerbaijan" },
    { value:"11", name: "Bahamas" },
    { value:"12", name: "Bahrain" },
    { value:"13", name: "Bangladesh" },
    { value:"14", name: "Barbados" },
    { value:"15", name: "Belarus" },
    { value:"16", name: "Belgium" },
    { value:"17", name: "Belize" },
    { value:"18", name: "Benin" },
    { value:"19", name: "Bermuda" },
    { value:"20", name: "Bhutan" },
    { value:"21", name: "Bolivia" },
    { value:"325", name: "Bonaire, Saint Eustatius and Saba" },
    { value:"22", name: "Bosnia and Herzegovina" },
    { value:"23", name: "Botswana" },
    { value:"181", name: "Bouvet Island" },
    { value:"24", name: "Brazil" },
    { value:"180", name: "Brunei Darussalam" },
    { value:"25", name: "Bulgaria" },
    { value:"26", name: "Burkina Faso" },
    { value:"27", name: "Burundi" },
    { value:"28", name: "Cambodia" },
    { value:"29", name: "Cameroon" },
    { value:"30", name: "Canada" },
    { value:"31", name: "Cape Verde" },
    { value:"32", name: "Cayman Islands" },
    { value:"33", name: "Central African Republic" },
    { value:"34", name: "Chad" },
    { value:"35", name: "Chile" },
    { value:"36", name: "China" },
    { value:"185", name: "Christmas Island" },
    { value:"37", name: "Colombia" },
    { value:"204", name: "Comoros" },
    { value:"38", name: "Congo" },
    { value:"183", name: "Cook Islands" },
    { value:"268", name: "Costa Rica" },
    { value:"275", name: "Cote D'Ivoire" },
    { value:"40", name: "Croatia" },
    { value:"276", name: "Cuba" },
    { value:"298", name: "Curacao" },
    { value:"41", name: "Cyprus" },
    { value:"42", name: "Czech Republic" },
    { value:"318", name: "Democratic Republic of the Congo" },
    { value:"43", name: "Denmark" },
    { value:"44", name: "Djibouti" },
    { value:"289", name: "Dominica" },
    { value:"187", name: "Dominican Republic" },
    { value:"45", name: "Ecuador" },
    { value:"46", name: "Egypt" },
    { value:"47", name: "El Salvador" },
    { value:"48", name: "Equatorial Guinea" },
    { value:"49", name: "Eritrea" },
    { value:"50", name: "Estonia" },
    { value:"51", name: "Ethiopia" },
    { value:"189", name: "Falkland Islands" },
    { value:"191", name: "Faroe Islands" },
    { value:"52", name: "Fiji" },
    { value:"53", name: "Finland" },
    { value:"54", name: "France" },
    { value:"193", name: "French Guiana" },
    { value:"277", name: "French Polynesia" },
    { value:"56", name: "Gabon" },
    { value:"57", name: "Gambia" },
    { value:"58", name: "Georgia" },
    { value:"59", name: "Germany" },
    { value:"60", name: "Ghana" },
    { value:"194", name: "Gibraltar" },
    { value:"61", name: "Greece" },
    { value:"195", name: "Greenland" },
    { value:"192", name: "Grenada" },
    { value:"196", name: "Guadeloupe" },
    { value:"62", name: "Guam" },
    { value:"198", name: "Guatemala" },
    { value:"270", name: "Guernsey" },
    { value:"63", name: "Guinea" },
    { value:"65", name: "Guyana" },
    { value:"200", name: "Haiti" },
    { value:"66", name: "Honduras" },
    { value:"67", name: "Hong Kong" },
    { value:"68", name: "Hungary" },
    { value:"69", name: "Iceland" },
    { value:"70", name: "India" },
    { value:"71", name: "Indonesia" },
    { value:"278", name: "Iran" },
    { value:"279", name: "Iraq" },
    { value:"74", name: "Ireland" },
    { value:"323", name: "Isle of Man" },
    { value:"75", name: "Israel" },
    { value:"76", name: "Italy" },
    { value:"202", name: "Jamaica" },
    { value:"78", name: "Japan" },
    { value:"288", name: "Jersey  (Channel Islands)" },
    { value:"79", name: "Jordan" },
    { value:"80", name: "Kazakhstan" },
    { value:"81", name: "Kenya" },
    { value:"203", name: "Kiribati" },
    { value:"82", name: "Kuwait" },
    { value:"83", name: "Kyrgyzstan" },
    { value:"84", name: "Lao People's Democratic Republic" },
    { value:"85", name: "Latvia" },
    { value:"86", name: "Lebanon" },
    { value:"87", name: "Lesotho" },
    { value:"88", name: "Liberia" },
    { value:"281", name: "Libya" },
    { value:"90", name: "Liechtenstein" },
    { value:"91", name: "Lithuania" },
    { value:"92", name: "Luxembourg" },
    { value:"208", name: "Macau" },
    { value:"93", name: "Macedonia" },
    { value:"94", name: "Madagascar" },
    { value:"95", name: "Malawi" },
    { value:"96", name: "Malaysia" },
    { value:"97", name: "Maldives" },
    { value:"98", name: "Mali" },
    { value:"99", name: "Malta" },
    { value:"207", name: "Marshall Islands" },
    { value:"210", name: "Martinique" },
    { value:"100", name: "Mauritania" },
    { value:"212", name: "Mauritius" },
    { value:"241", name: "Mayotte" },
    { value:"101", name: "Mexico" },
    { value:"102", name: "Moldova, Republic of" },
    { value:"103", name: "Monaco" },
    { value:"104", name: "Mongolia" },
    { value:"290", name: "Montenegro" },
    { value:"294", name: "Montserrat" },
    { value:"105", name: "Morocco" },
    { value:"106", name: "Mozambique" },
    { value:"242", name: "Myanmar" },
    { value:"107", name: "Namibia" },
    { value:"108", name: "Nepal" },
    { value:"109", name: "Netherlands" },
    { value:"110", name: "Netherlands Antilles" },
    { value:"213", name: "New Caledonia" },
    { value:"111", name: "New Zealand" },
    { value:"112", name: "Nicaragua" },
    { value:"113", name: "Niger" },
    { value:"114", name: "Nigeria" },
    { value:"217", name: "Niue" },
    { value:"214", name: "Norfolk Island" },
    { value:"272", name: "North Korea" },
    { value:"116", name: "Norway" },
    { value:"117", name: "Oman" },
    { value:"118", name: "Pakistan" },
    { value:"222", name: "Palau" },
    { value:"282", name: "Palestine" },
    { value:"119", name: "Panama" },
    { value:"219", name: "Papua New Guinea" },
    { value:"120", name: "Paraguay" },
    { value:"121", name: "Peru" },
    { value:"122", name: "Philippines" },
    { value:"221", name: "Pitcairn" },
    { value:"123", name: "Poland" },
    { value:"124", name: "Portugal" },
    { value:"126", name: "Qatar" },
    { value:"315", name: "Republic of Kosovo" },
    { value:"127", name: "Reunion" },
    { value:"128", name: "Romania" },
    { value:"129", name: "Russia" },
    { value:"130", name: "Rwanda" },
    { value:"205", name: "Saint Kitts and Nevis" },
    { value:"206", name: "Saint Lucia" },
    { value:"324", name: "Saint Martin" },
    { value:"237", name: "Saint Vincent and the Grenadines" },
    { value:"132", name: "Samoa (Independent)" },
    { value:"227", name: "San Marino" },
    { value:"255", name: "Sao Tome and Principe" },
    { value:"133", name: "Saudi Arabia" },
    { value:"134", name: "Senegal" },
    { value:"326", name: "Serbia" },
    { value:"135", name: "Seychelles" },
    { value:"136", name: "Sierra Leone" },
    { value:"137", name: "Singapore" },
    { value:"302", name: "Sint Maarten" },
    { value:"138", name: "Slovakia" },
    { value:"139", name: "Slovenia" },
    { value:"223", name: "Solomon Islands" },
    { value:"140", name: "Somalia" },
    { value:"141", name: "South Africa" },
    { value:"257", name: "South Georgia and the South Sandwich Islands" },
    { value:"142", name: "South Korea" },
    { value:"311", name: "South Sudan" },
    { value:"143", name: "Spain" },
    { value:"144", name: "Sri Lanka" },
    { value:"293", name: "Sudan" },
    { value:"146", name: "Suriname" },
    { value:"225", name: "Svalbard and Jan Mayen Islands" },
    { value:"147", name: "Swaziland" },
    { value:"148", name: "Sweden" },
    { value:"149", name: "Switzerland" },
    { value:"285", name: "Syria" },
    { value:"152", name: "Taiwan" },
    { value:"260", name: "Tajikistan" },
    { value:"153", name: "Tanzania" },
    { value:"154", name: "Thailand" },
    { value:"233", name: "Timor-Leste" },
    { value:"155", name: "Togo" },
    { value:"232", name: "Tonga" },
    { value:"234", name: "Trinidad and Tobago" },
    { value:"156", name: "Tunisia" },
    { value:"157", name: "Turkey" },
    { value:"158", name: "Turkmenistan" },
    { value:"287", name: "Turks &amp; Caicos Islands" },
    { value:"159", name: "Uganda" },
    { value:"161", name: "Ukraine" },
    { value:"162", name: "United Arab Emirates" },
    { value:"262", name: "United Kingdom" },
    { value:"163", name: "Uruguay" },
    { value:"165", name: "Uzbekistan" },
    { value:"239", name: "Vanuatu" },
    { value:"166", name: "Vatican City State (Holy See)" },
    { value:"167", name: "Venezuela" },
    { value:"168", name: "Vietnam" },
    { value:"169", name: "Virgin Islands (British)" },
    { value:"238", name: "Virgin Islands (U.S.)" },
    { value:"188", name: "Western Sahara" },
    { value:"170", name: "Yemen" },
    { value:"173", name: "Zambia" },
    { value:"174", name: "Zimbabwe" }
  ]

  constructor(private http: HttpClient) {
    this.country = this.countries.find(c => c.value === '262');
  }

  ngOnInit(): void {
  }

  onSubmit(event: Event) {
    this.error = '';
    if (this.emailControl.status === 'VALID') {

      const address = {
        addr1 : this.address1,
        addr2 : this.address2,
        city : this.town,
        state: this.county,
        zip: this.postcode,
        country: this.country.value
      }
      
      this.submitted = true;
      this.waiting = true;
      const params = new HttpParams()
        .set('FNAME', this.firstName)
        .set('LNAME', this.lastName)
        .set('EMAIL', this.emailControl.value)
        .set('PHONE', this.phone)

        .set('ADDRESS[addr1]', this.address1)
        .set('ADDRESS[addr2]', this.address2)
        .set('ADDRESS[city]', this.town)
        .set('ADDRESS[state]', this.county)
        .set('ADDRESS[zip]', this.postcode)
        .set('ADDRESS[country]', this.country.value)

        // .set('ADDRESS', JSON.stringify(address))
        .set('b_4118053db977662ef278620b3_e3136c0c02', this.botString); // hidden input name

      const mailChimpUrl = this.mailChimpEndpoint + params.toString();

      this.http.jsonp<MailChimpResponse>(mailChimpUrl, 'c').subscribe(response => {
        if (response.result && response.result !== 'error') {
          this.success = true;
          this.waiting = false;
        }
        else {
          this.error = response.msg;
          this.waiting = false;
        }
      }, error => {
        console.error(error);
        this.error = 'Sorry, an error occurred.';
        this.waiting = false;
      });
    }
  }

}
