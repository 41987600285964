<div class="container main-container" #register>
    <div class="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
        <h1 class="display-4 calligraphy">Register</h1>
        <p>Formal invite to follow... but we need some details from you.</p>
    </div>

    <div *ngIf="waiting" class="d-flex justify-content-center my-5">
        <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>

    <div *ngIf="success">
        <p>Thanks for giving us your details! We will send invitations and more details soon.</p>
    </div>

    <div *ngIf="error">
        <span [innerHtml]="error"></span>
    </div>

    <div [hidden]="submitted">
        <form (ngSubmit)="onSubmit($event)">

            <legend>Contact Details</legend>
            <div class="form-row">
                <div class="form-group col-md-6">
                    <input type="text" class="form-control" id="firstNameInput" placeholder="First Name" name="firstName1" [(ngModel)]="firstName" autocomplete="given-name">
                </div>

                <div class="form-group col-md-6">
                    <input type="text" class="form-control" id="lastNameInput" placeholder="Last Name" name="lastName1" [(ngModel)]="lastName" autocomplete="family-name">
                </div>
            </div>

            <div class="form-row">
                <div class="form-group col-md-12">
                    <input type="email" class="form-control" id="inputEmail" aria-describedby="emailHelp" placeholder="Email" required name="email" [formControl]=emailControl>
                </div>
            </div>

            <div class="form-row">
                <div class="form-group col-md-12">
                    <label for="inputAddress">Address</label>
                    <input type="text" class="form-control" placeholder="Address Line 1" id="inputAddress" name="address1" [(ngModel)]="address1" autocomplete="address-line1">
                </div>

                <div class="form-group col-md-12">
                    <input type="text" class="form-control" placeholder="Address Line 2" id="inputAddress2" name="address2" [(ngModel)]="address2" autocomplete="address-line2">
                </div>

                <div class="form-group col-md-12">
                    <input type="text" class="form-control" placeholder="Town" id="inputCity" name="town" [(ngModel)]="town">
                </div>

                <div class="form-group col-md-4">
                    <label for="inputState">County</label>
                    <input type="text" class="form-control" id="inputState" name="state" [(ngModel)]="county">
                </div>

                <div class="form-group col-md-4">
                    <label for="inputZip">Postcode</label>
                    <input type="text" class="form-control" id="inputZip" name="zip" [(ngModel)]="postcode" autocomplete="shipping postal-code">
                </div>

                <div class="form-group col-md-4">
                    <label for="inputZip">Phone</label>
                    <input type="tel" class="form-control" id="phone" name="phone" [(ngModel)]="phone">
                </div>

                <div class="form-group col-md-12">
                    <label for="inputState">Country</label>
                    <select id="inputState" class="form-control" name="country" [(ngModel)]="country">
                        <option *ngFor="let c of countries" [ngValue]="c">{{c.name}}</option>
                    </select>
                </div>
            </div>


            <button type="submit" class="btn btn-dark mb-3">Submit</button>
        </form>
    </div>