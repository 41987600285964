<div class="banner-img">
    <div class="logo-container py-5 d-flex flex-column justify-content-around h-100">
        <div class="text-center text-white">
            <div [class]="dateClass">
                <span class="calligraphy shad">save the date</span>
            </div>
            <div class="img-shadow">
                <img src="/assets/vt-white.svg">
            </div>
            <div class="text-center text-white shad" [class]="dateClass">
                <span class="calligraphy">perthshire</span>
                <span class="deco mx-3">D</span>
                <span class="calligraphy">26&bull;03&bull;2022</span>
            </div>
        </div>

        <div class="text-center text-white my-5">
            <button class="btn btn-light" (click)="scrollToElement(venue)">
              <fa-icon class="fa-lg" [icon]="faChevronDown"></fa-icon>
            </button>
        </div>
    </div>
</div>
<!-- <div #register>
    <app-register></app-register>
</div> -->

<div #venue>
    <app-venue></app-venue>
</div>