<nav class="navbar navbar-expand-md navbar-light bg-light fixed-top calligraphy">
    <a class="navbar-brand" routerLink="/home">
        <!--    <span>Victoria & Thomas</span>-->
        <img src="assets/vt-horizontal.svg" alt="Victoria and Thomas" style="height:1.8em;">
    </a>

    <button class="navbar-toggler hidden-sm-up" type="button" (click)="isNavbarCollapsed = !isNavbarCollapsed" data-target="#navbarsDefault" aria-controls="navbarsDefault" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

    <div [ngbCollapse]="isNavbarCollapsed" class="collapse navbar-collapse bigger" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">
            <!-- <li class="nav-item">
                <a class="nav-link" [routerLink]="['/rsvp']" (click)="hideMenu()">RSVP</a>
            </li> -->
            <li class="nav-item">
                <a class="nav-link" [routerLink]="['/savethedate']" (click)="hideMenu()">Save The Date</a>
            </li>
            <!-- <li class="nav-item">
                <a class="nav-link" [routerLink]="['/venue']" (click)="hideMenu()">Location</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [routerLink]="['/about']" (click)="hideMenu()">About</a>
            </li> -->
        </ul>
    </div>
</nav>