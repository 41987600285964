<div class="page-container">
    <div class="section py-5">
        <div class="section-content text-center">

            <div class="d-flex flex-column ">
                <span class="caps time">Saturday Two O'Clock</span>
                <span class="calligraphy date">26th March 2022</span>
                <span class="caps time">Blair Castle, Blair Atholl, Perthshire</span>
            </div>

            <div class="d-flex flex-row justify-content-center w-100 mt-5">
                <div class="w-50"><img class="img-fluid" src="/assets/vttc.svg"></div>
            </div>

            <div class="d-flex flex-row justify-content-center w-100 mt-5">
                <div class="w-25"><img class="img-fluid" src="/assets/blair-castle.png"></div>
                <div class="w-25"><img class="img-fluid" src="/assets/atholl.png"></div>
            </div>

        </div>
    </div>

    <div class="section bg-light py-5">
        <div class="section-content text-center">
            <h1 class="calligraphy">Getting There</h1>
            <p>We usually drive up from the south coast, but it is quite a long journey. both Glasgow & Edinburgh airports are 90 minutes to two hours by car. Blair Atholl train station is only 100m from the Atholl Arms hotel, but the trains are not fast
                so it might be better to hire a car or share a cab from the airport.</p>

            <h1 class="calligraphy">Where to Stay</h1>
            <p>There are about 30 rooms available for the Friday & Saturday night (or longer) at the <a href="https://athollarmshotel.co.uk">Atholl Arms Hotel</a>. please email <a href="mailto:clara@athollarmshotel.co.uk">clara@athollarmshotel.co.uk</a>                for information and to book There is glamping, camping and lodges nearby at Blair Castle see <a href="https://www.blair-castle.co.uk">www.blair-castle.co.uk</a> for information. If the Atholl is full then <a href="https://www.moulinhotel.co.uk">The Moulin Inn</a>                is a good option as we will be having drinks & nibbles there on the Friday evening. If lots of you end up here then we will arrange transport from the reception.</p>

            <h1 class="calligraphy">Everything Else</h1>
            <p>Your presence is our present, so no need to buy us a toaster. If you have any questions about accomodation, travel or the big day itself then feel free to get in touch by email or phone.</p>
            <p><a href="tel:+447717204317">Tom +44 (0)7717 204317</a></p>
            <p><a href="tel:+447540449714">Tor +44 (0)7540 449714</a></p>
            <p><a href="mailto:wedding@tomandtor.com">wedding@tomandtor.com</a></p>
        </div>
    </div>

</div>