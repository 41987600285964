import { Component, OnInit } from '@angular/core';
import {faFacebook, faInstagram, faSpotify} from '@fortawesome/free-brands-svg-icons';
import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  faFacebook = faFacebook;
  faInstagram = faInstagram;
  faSpotify = faSpotify;
  mobile = false;
  tiny = false;


  constructor(public breakpointObserver: BreakpointObserver) { }

  ngOnInit(): void {
    this.breakpointObserver
      .observe(['(min-width: 500px)'])
      .subscribe((state: BreakpointState) => {
        this.mobile = !state.matches;
      });

    this.breakpointObserver
      .observe(['(min-width: 360px)'])
      .subscribe((state: BreakpointState) => {
        this.tiny = !state.matches;
      });
  }
}
