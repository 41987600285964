import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  mobile = false;
  tiny = false;

  faChevronDown = faChevronDown;
  constructor(public breakpointObserver: BreakpointObserver) { }

  ngOnInit(): void {
    this.breakpointObserver
      .observe(['(min-width: 500px)'])
      .subscribe((state: BreakpointState) => {
        this.mobile = !state.matches;
      });

    this.breakpointObserver
      .observe(['(min-width: 360px)'])
      .subscribe((state: BreakpointState) => {
        this.tiny = !state.matches;
      });
  }

  get dateClass(): string {
    if (!this.mobile) {
      return 'date-container-lg';
    }
    if (!this.tiny) {
      return 'date-container-sm';
    }
    return 'date-container-xs';
  }

  scrollToElement($element: any): void {
    $element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }
}
